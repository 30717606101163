<section class="component-wrapper msg-component-wrapper">
  <div class="header-content">
    <div class="header-agreement-wrapper">
      <p class="header" *ngIf="!toggleHeadlines">Vertrag wählen</p>
      <p class="header" *ngIf="toggleHeadlines">Vertrag</p>
    </div>
  </div>
  <div class="select-agreement-wrapper">
    <mat-radio-group class="msg-radio-button-wrapper" aria-label="selectedMessage"
      [disabled]="disableRadioButtonGroupAgreements">
      <div class="select-agreement" *ngFor="let contract of contracts">
        <app-select-agreement [contract]="contract" [toggleWhenCreateMessage]="displayAgreements"
          (onAgreementSelected)="filterMessageTypes($event)" [id]="contract.contractId"  [disabled]="disableRadioButtonGroupAgreements">
      </app-select-agreement>
      </div>
    </mat-radio-group>
  </div>
  <div class="header-content" *ngIf="showMessageType">
    <div class="header-message-type-wrapper">
      <p class="header" *ngIf="!toggleHeadlines">Nachrichtenart wählen</p>
      <p class="header" *ngIf="toggleHeadlines">Nachrichtenart</p>
    </div>
  </div>
  <div class="select-message-type-wrapper" *ngIf="showMessageType">
    <mat-radio-group class="msg-type-radio-button-wrapper" aria-label="selectedMessageTypes"
      [disabled]="disableRadioButtonGroupMessageTypes">
      <div class="select-message-type" *ngFor="let messageType of allMessageTypes">
        <app-select-message-type [messageType]="messageType" (onMessageTypeSelected)="allowCreateMessage($event)"
          [toggleWhenCreateMessage]="displayAgreements" [disabled]="disableRadioButtonGroupMessageTypes">
        </app-select-message-type>
      </div>
    </mat-radio-group>
  </div>
  <div class="header-content" *ngIf="showCreateMessageComponent">
    <div class="header-message-type-wrapper">
      <p class="header">Ihre Nachricht</p>
    </div>
  </div>
  <div class="create-message-form-wrapper" *ngIf="showCreateMessageComponent && !originalMessage">
    <div class="create-message-form" *ngIf="messageForm">
      <app-create-message-form [messageForm]="messageForm.form" [recipient]="messageForm.recipient?.fullName" [title]="messageForm.title" (submitFormMessageId)="onSubmitFormMessageId($event)" (submitForm)="onSubmitForm($event)"></app-create-message-form>
    </div>
  </div>
  <div class="file-upload" *ngIf="originalMessage">
    <div class="original-message">
      <div class="rendered-message" [innerHTML]="originalMessage"></div>
    </div>
    <div class="header-content">
      <div class="header-message-type-wrapper">
        <p class="header-long">Alle Anlagen</p>
      </div>
    </div>
    <div class="file-upload-wrapper">
      <div *ngFor="let attachment of this.attachmentsWithThumbnail">
        <app-thumbnail-box [messageAttachment]="attachment"></app-thumbnail-box>
      </div>
      <app-file-upload [messageId]="this.messageId" (uploadDialogClosed)="this.buildThumbnails()"></app-file-upload>
    </div>
  </div>
  <div class="button-wrapper" *ngIf="!showSendButton">
    <mat-button class="button abort secondary" (click)="goBackToInbox()">
      <p class="text">Abbrechen</p>
    </mat-button>
    <mat-button class="button proceed primary" [ngClass]="allowCreateMessageComponent ? false : 'inactive'" (click)="displayToCreateMessage()">
      <p class="text-long">Weiter und Nachricht verfassen</p>
      <p class="text-short">Nachricht verfassen</p>
    </mat-button>
  </div>

  <div class="button-wrapper" *ngIf="originalMessage">
    <mat-button color="primary" class="button complete primary" (click)="goBackToInbox()">
      <p class="text">Abschließen</p>
    </mat-button>
  </div>

  <div class="contact-panel-wrapper" *ngIf="messageForm?.recipient">
    <app-contact-panel class="contact-panel" [contact]="messageForm.recipient"></app-contact-panel>
  </div>
</section>
