<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="heading-content">
        <div class="heading-element-wrapper">
          <div class="arrow-box">
            <div class="arrow">
              <svg id="pfeil_unten_mit_kreis" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#083f64;}</style></defs><circle class="cls-1" cx="25" cy="25" r="15.88"/><path class="cls-2" d="M25,27.26l-7.44-7.44a1.53,1.53,0,0,0-2.17,0L14,21.25a1.55,1.55,0,0,0,0,2.18l7.44,7.44,2.52,2.52a1.55,1.55,0,0,0,2.18,0l2.52-2.52,7.44-7.44a1.55,1.55,0,0,0,0-2.18l-1.44-1.43a1.53,1.53,0,0,0-2.17,0Z"/></svg>
            </div>
          </div>
          <div class="left-elements">
            <p class="description">{{ contract.info1 }}</p>
            <p>{{ contract.unitStreetAndNo }}</p>
            <p class="postal-code-location">{{ contract.unitPostcode + " " + contract.unitCity }}</p>
            <p>Vertragsnummer: {{ contract.number }}</p>
          </div>
        </div>
        <div class="right-elements">
          <p>{{ contract.unitInfo1 }}</p>
          <p>{{ contract.unitInfo2 }}</p>
          <p>{{ contract.unitInfo3 }}</p>
        </div>
      </div>
      <div class="action-elements">
        <div class="doc-buttons">
          <a class="doc-view" (click)="toDocuments(contract.number + ' - ' + contract.unitInfo3)">
            <button mat-button>
              <img class="img-icon" src="assets/media/meinedokumente3_negativ.svg">
            </button>
          </a>
          <a class="doc-view" (click)="toMessages(contract.number)">
            <button mat-button>
              <img class="img-icon" src="assets/media/Nachrichten_w.svg">
            </button>
          </a>
        </div>
      </div>
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="content-container">
      <section class="agreement-content">
        <div class="text-couple very-small-margin-bottom">
          <div class="text-composer">
            <div>Vertragsbeginn: </div>
            <div>Vertragsende: </div>
          </div>
          <div class="text-composer">
            <div>{{ contract.start }}</div>
            <div>{{ contract.end }}</div>
          </div>
        </div>
        <div class="text-couple very-small-margin-bottom">
          <div class="text-composer">
            <div>{{ contract.info1 }}</div>
            <div>{{ contract.info2 }}</div>
          </div>
          <div class="text-composer">
            <div>{{ contract.info3 }}</div>
            <div>{{ contract.info4 }}</div>
          </div>
        </div>
        <div class="text-couple">
          <div class="text-composer">
            <div>Fläche: </div>
            <div>Gesamtmiete: </div>
          </div>
          <div class="text-composer right-alignment">
            <div>{{ contract.unitArea }} qm</div>
            <div>{{ contract.rent }} €</div>
          </div>
        </div>
      </section>
      <section class="contact-wrapper">
        <div class="agreement-contact">
          <h3 class="mobile">Ansprechpartner:</h3>
          <div class="text-composer verwalter">
            <div class="contact-icon-wrapper desktop" *ngIf="contract.contactPersonsMax[0]">
              <img src={{MEINACCOUNT}}>
            </div>
            <div class="contact-contents" *ngIf="contract.contactPersonsMax[0]">
              <h3 class="desktop">{{ contract.contactPersonsMax[0].role }}</h3>
              <div>{{ contract.contactPersonsMax[0].salutation }}</div>
              <div *ngIf="contract.contactPersonsMax[0].phone">
                <a href='tel: "{{contract.contactPersonsMax[0].phone}}"'>{{ contract.contactPersonsMax[0].phone }}</a>
              </div>
              <div *ngIf="contract.contactPersonsMax[0].mobile">
                <a href='tel: "{{ contract.contactPersonsMax[0].mobile }}"'>{{ contract.contactPersonsMax[0].mobile }}</a>
              </div>
              <div class="desktop">
                <a href='mailto: {{ contract.contactPersonsMax[0].mail }}'>{{ contract.contactPersonsMax[0].mail }}</a>
              </div>
              <br>
              <div class="desktop">{{ contract.contactPersonsMax[0].company }}</div>
              <div class="contact-street-location desktop">
                <span>{{ contract.contactPersonsMax[0].streetAndNo }}</span>
                <span>{{ contract.contactPersonsMax[0].postcode + " " + contract.contactPersonsMax[0].city }}</span>
              </div>
            </div>
          </div>
          <div class="text-composer hausmeister">
            <div class="contact-icon-wrapper desktop" *ngIf="contract.contactPersonsMax[1]">
              <img src={{MEINACCOUNT}}>
            </div>
            <div class="contact-contents" *ngIf="contract.contactPersonsMax[1]">
              <h3 class="desktop">{{ contract.contactPersonsMax[1].role }}</h3>
              <div>{{ contract.contactPersonsMax[1].salutation }}</div>
              <div *ngIf="contract.contactPersonsMax[1].phone">
                <a href='tel: "{{ contract.contactPersonsMax[1].phone}}"'>{{ contract.contactPersonsMax[1].phone }}</a>
              </div>
              <div *ngIf="contract.contactPersonsMax[1].mobile">
                <a href='tel: "{{ contract.contactPersonsMax[1].mobile }}"'>{{ contract.contactPersonsMax[1].mobile }}</a>
              </div>
              <div class="desktop">
                <a href='mailto: {{ contract.contactPersonsMax[1].mail }}'>{{ contract.contactPersonsMax[1].mail }}</a>
              </div>
              <br>
              <div class="desktop">{{ contract.contactPersonsMax[1].company }}</div>
              <div class="contact-street-location desktop">
                <span>{{ contract.contactPersonsMax[1].streetAndNo }}</span>
                <span>{{ contract.contactPersonsMax[1].postcode + " " + contract.contactPersonsMax[1].city }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="agreement-emergency">
          <div class="contact-icon-wrapper desktop spaeter">
            <img class="contact-icon" src="assets/media/Telefon_gr.svg">
          </div>
          <div class="spaeter">
            <h3>
              <span class="mobile">Notrufnummer:</span>
              <span class="desktop">Notrufnummern</span>
            </h3>
            <div *ngFor="let contact of contract.contactPersons" class="text-composer">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </ng-template>
</mat-expansion-panel>
<div class="doc-buttons small-margin-bottom">
  <a class="doc-view" (click)="toDocuments(contract.number + ' - ' + contract.unitInfo3)">
    <button mat-button>
      <img class="img-icon" src="assets/media/meinedokumente3_negativ.svg">
    </button>
  </a>
  <a class="msg-view" (click)="toMessages(contract.number)">
    <button mat-button>
      <img class="img-icon" src="assets/media/Nachrichten_w.svg">
    </button>
  </a>
</div>
