import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contract } from 'src/app/model/Contract';
import { DocumentService } from 'src/app/services/document/document-service.service';
import { MessageService } from 'src/app/services/message/message-service.service';
import { StyleService } from 'src/app/services/style/style.service';

@Component({
  selector: 'app-agreement-panel',
  templateUrl: './agreement-panel.component.html',
  styleUrls: ['./agreement-panel.component.scss']
})
export class AgreementPanelComponent implements OnInit {

  @Input() contract: Contract;

  constructor(private router: Router, private documentService: DocumentService, private messageService: MessageService,private styleService: StyleService) { }
  MEINACCOUNT: string = "";
  ngOnInit(): void {
    this.getMeinAccountIcon();
  }

  toDocuments(agreementNumber: string) {
    this.documentService.setAgreementInput(agreementNumber, true);
    this.router.navigateByUrl("/documents", { state: { number: agreementNumber } });
  }

  toMessages(agreementNumber: string) {
    this.messageService.setAgreementInput(agreementNumber, true);
    this.router.navigateByUrl("/messages", { state: { number: agreementNumber } });
  }
  getMeinAccountIcon() {
    this.styleService.loadStyleConfig().subscribe( styles => {
       this.MEINACCOUNT = this.styleService.getMeinAccountIcon(styles);
    });
  }
}
